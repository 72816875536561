// Libraries
import axios from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import singelPartnerDefault from "~/defaults/partner/singelPartner";

interface fetchBodyType {
  fetchBody: {
    end: string;
    start: string;
    sortBy: "bad" | "best" | "old" | "new";
    sortDesc: string;
    businessCustomerId: string;
    page: string;
  };
  clear: boolean;
}

@Module({
  name: "partner",
  namespaced: true,
  stateFactory: true,
})
export default class Partner extends VuexModule {
  singlePartner = Object.assign({}, singelPartnerDefault);

  partnerList = [];

  @Mutation
  setSinglePartner(data) {
    this.singlePartner.profile._id = data._id;
    this.singlePartner.profile.isActivated = data.isActivated;
    this.singlePartner.profile.isOffering = data.isOffering;
    this.singlePartner.profile.name = data.companyName;
    this.singlePartner.profile.preferences.slug = data.preferences.slug;
    this.singlePartner.profile.legalName = data.companyName;
    this.singlePartner.profile.profileId = data.profileId;
    this.singlePartner.profile.groupId = data.companyProfile._id;
    this.singlePartner.profile.companyProfile.description =
      data.companyProfile.description;
    this.singlePartner.profile.companyProfile.imprint =
      data.companyProfile.imprint;
    this.singlePartner.profile.companyProfile.vatId = data.VATId;
    this.singlePartner.profile.companyProfile.address =
      data.companyProfile.address;
    this.singlePartner.profile.companyProfile.contactPoint =
      data.companyProfile.contactPoint;
    this.singlePartner.profile.companyProfile.awards.ralCheck =
      data.awards.ralCheck;
    this.singlePartner.profile.companyProfile.awards.tankCheck =
      data.awards.tankCheck;
    this.singlePartner.profile.companyProfile.hasAGB =
      data.companyProfile.hasAGB;
    this.singlePartner.profile.companyProfile.hasWithdrawal =
      data.companyProfile.hasWithdrawal;
    this.singlePartner.profile.companyProfile.arbiterShowText =
      data.companyProfile.arbiterShowText;
    this.singlePartner.profile.isTesting = data.isTesting;
    this.singlePartner.profile.shortName = data.shortName;
    if (data.preferences !== null) {
      this.singlePartner.profile.preferences.averageRating =
        data.preferences.averageRating;
      this.singlePartner.profile.preferences.countRating =
        data.preferences.countRating;
      this.singlePartner.profile.preferences.ratingsLastYear.averageRating =
        data.preferences.ratingLastYear.averageRating;
      this.singlePartner.profile.preferences.ratingsLastYear.countRating =
        data.preferences.ratingLastYear.countRating;
      this.singlePartner.profile.preferences.ratingsLastYear.fiveStarRatings =
        data.preferences.ratingLastYear.fiveStarRatings;
      this.singlePartner.profile.preferences.ratingsLastYear.fourStarRatings =
        data.preferences.ratingLastYear.fourStarRatings;
      this.singlePartner.profile.preferences.ratingsLastYear.threeStarRatings =
        data.preferences.ratingLastYear.threeStarRatings;
      this.singlePartner.profile.preferences.ratingsLastYear.twoStarRatings =
        data.preferences.ratingLastYear.twoStarRatings;
      this.singlePartner.profile.preferences.ratingsLastYear.oneStarRatings =
        data.preferences.ratingLastYear.oneStarRatings;
    }
    if (data.companyProfile.address !== null) {
      if (data.companyProfile.address.addressType)
        this.singlePartner.profile.address.addressType =
          data.companyProfile.address.addressType;
      if (data.companyProfile.address.addressCountry)
        this.singlePartner.profile.address.addressCountry =
          data.companyProfile.address.addressCountry;
      if (data.companyProfile.address.city)
        this.singlePartner.profile.address.city =
          data.companyProfile.address.city;
      if (data.companyProfile.address.postalCode)
        this.singlePartner.profile.address.postalCode =
          data.companyProfile.address.postalCode;
      if (data.companyProfile.address.street)
        this.singlePartner.profile.address.street =
          data.companyProfile.address.street;
      if (data.companyProfile.address.streetNumber)
        this.singlePartner.profile.address.streetNumber =
          data.companyProfile.address.streetNumber;
    }
    this.singlePartner.profile.orderContactEmail = data.orderContactEmail;
    this.singlePartner.profile.orderContactPhone = data.orderContactPhone;
  }

  @Mutation
  clearPartnersUserComments() {
    this.singlePartner.userComments.data = [];
    return true;
  }

  @Mutation
  setPartnersUserComments(userComments) {
    this.singlePartner.userComments.loadVolume = userComments.metadata.total;
    this.singlePartner.userComments.loadedAmount = userComments.metadata.limit;
    userComments.data.forEach((comment) => {
      this.singlePartner.userComments.data.push(comment);
    });
  }

  @Mutation
  setPartnerMediaUploads(data) {
    this.singlePartner.uploads.logoImageUrls.small = `${process.env.PARTNER_MEDIA_CDN}/${data.logoImageUrls.small}`;
    this.singlePartner.uploads.logoImageUrls.medium = `${process.env.PARTNER_MEDIA_CDN}/${data.logoImageUrls.medium}`;
    this.singlePartner.uploads.logoImageUrls.large = `${process.env.PARTNER_MEDIA_CDN}/${data.logoImageUrls.large}`;

    this.singlePartner.uploads.profileImages = [];
    data.profileImages.forEach((image) => {
      this.singlePartner.uploads.profileImages.push(
        `${process.env.PARTNER_MEDIA_CDN}/${image}`,
      );
    });

    this.singlePartner.uploads.privacyPolicyFileUrl = `${process.env.PARTNER_MEDIA_CDN}/${data.withdrawalFileUrl}`;
    this.singlePartner.uploads.withdrawalFileUrl = `${process.env.PARTNER_MEDIA_CDN}/${data.withdrawalFileUrl}`;
    this.singlePartner.uploads.agbFileUrl = `${process.env.PARTNER_MEDIA_CDN}/${data.agbFileUrl}`;
  }

  @Mutation
  clearPartnerList() {
    this.partnerList = [];
  }

  @Mutation
  setPartnerList(partner) {
    this.partnerList = partner;
  }

  @Action
  async fetchPartnerListItems(partnerName: string) {
    try {
      const result = await axios({
        method: "post",
        url: `${process.env.BACKBONE_ESYOIL}${process.env.PARTNER_PROFILE_LIST}`,
        data: { legalName: partnerName },
      });
      // keine offline oder Test Dealer in der Liste
      const filteredData = result.data.filter(
        (ele) => ele.isOffering && !ele.isTesting,
      );

      this.context.commit("clearPartnerList");
      this.context.commit("setPartnerList", filteredData);
    } catch (error) {
      this.context.commit("clearPartnerList");
    }
  }

  @Action
  async fetchPartnerMediaUploads(groupId) {
    const result = await axios.get(
      `${process.env.BACKBONE_ESYOIL}${process.env.PARTNER_MEDIA_UPLOADS}/${groupId}`,
    );
    this.context.commit("setPartnerMediaUploads", result.data);
  }

  @Action
  async loadUserComments(fetchBody: fetchBodyType) {
    if (fetchBody.clear === true) {
      this.context.commit("clearPartnersUserComments");
    }
    try {
      const result = await axios.post(
        `${process.env.BACKBONE_ESYOIL}${process.env.PARTNER_RATING}/5/${fetchBody.fetchBody.page}`,
        fetchBody.fetchBody,
      );
      this.context.commit("setPartnersUserComments", result.data);
    } catch (e) {
      console.warn(e);
    }
  }

  @Action
  async fetchPartnersRatings(fetchBody: fetchBodyType) {
    this.context.commit("clearPartnersUserComments");
    try {
      const result = await axios.post(
        `${process.env.BACKBONE_ESYOIL}${process.env.PARTNER_RATING}/5/${fetchBody.fetchBody.page}`,
        fetchBody,
      );
      this.context.commit("setPartnersUserComments", result.data);
    } catch (error) {
      //
    }
  }

  @Action
  async fetchSinglePartnerData(data: { slug: string; $nuxt: any }) {
    try {
      const result = await axios.get(
        `${process.env.BACKBONE_ESYOIL}${process.env.PARTNER_PROFILE}/${data.slug}`,
      );
      this.context.commit("setSinglePartner", result.data);
      await Promise.all([
        this.context.dispatch(
          "fetchPartnerMediaUploads",
          result.data.profileId,
        ),
        this.context.dispatch(
          "fetchPartnerBranchAddresses",
          result.data.groupId,
        ),
      ]);
    } catch (error) {
      data.$nuxt.error({ statusCode: 404 });
    }
  }

  @Mutation
  setBranchAddressData(AddressDataArray) {
    this.singlePartner.branchAddresses = [];
    this.singlePartner.branchAddresses = AddressDataArray;
  }
  @Action
  async fetchPartnerBranchAddresses(partnerID: string) {
    const result = await axios.get(
      `${process.env.BACKBONE_ESYOIL}${process.env.PARTNER_BRANCH_ADDRESSES}/${partnerID}`,
    );
    this.context.commit("setBranchAddressData", result.data);
  }

  @Mutation
  resetPartnerData() {
    this.singlePartner = Object.assign({}, singelPartnerDefault);

    this.partnerList = [];
  }
}
